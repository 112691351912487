import Markdown from 'markdown-to-jsx'
import styled from 'styled-components'
import { H1, H2, H3, TextBody } from 'src/styles/Typography'
import grabColor from 'src/utils/grabColor'
// import CodeSnippetMarkdown from './CodeSnippetMarkdown'
import ImageMarkdown from './ImageMarkdown'

const ContentMarkdown = ({ content }) => {
  return (
    <Markdown
      options={{
        overrides: {
          p: TextBody,
          img: ImageMarkdown,
          h1: H1,
          h2: StyledH2,
          h3: H3,
          h4: StyledH4,
          li: Li,
        },
      }}
    >
      {content}
    </Markdown>
  )
}

export default ContentMarkdown

const StyledH2 = styled(H2)`
  margin: 2rem 0 1rem;
  padding-top: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: 24px;
  }
`

const StyledH4 = styled.span`
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin: 1rem 0;
  line-height: 24px;
  color: ${grabColor('heading')};
`

const Li = styled.li`
  color: ${grabColor('base')};
  font-size: 14px;
`
