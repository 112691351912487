import Image from "next/legacy/image";
import styled from 'styled-components'

const ImageMarkdown = ({ src }) => {
  return (
    <ImageWraper>
      <Image src={`https:${src}`} width={600} height={600} alt="" />
    </ImageWraper>
  )
}

export default ImageMarkdown

const ImageWraper = styled.div`
  margin: 3rem 0;
`
