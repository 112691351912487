function SvgCheckmark(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.408 15.764a.835.835 0 001.163 0l9.235-9.235a.835.835 0 000-1.163l-1.13-1.13a.796.796 0 00-1.132 0l-7.539 7.539-3.55-3.518a.796.796 0 00-1.13 0l-1.131 1.13a.835.835 0 000 1.163l5.214 5.214z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCheckmark
