import styled from 'styled-components'
import Container from 'src/styles/Container'
import { H2Alt, H3, TextBody } from 'src/styles/Typography'
import SvgCheckmark from 'src/components/icons/CheckMark'
import grabColor from 'src/utils/grabColor'
import ContentMarkdown from 'src/components/ContentMarkdown'

export default function HomeFAQ({ faqList }) {
  return (
    <HomeFAQWrapper>
      <Container>
        <HomeFAQInner>
          <div>
            <H2Alt>{faqList.title}</H2Alt>
          </div>
          <BottomContainer>
            {faqList.itemsCollection.items.map(({ question, answer }, index) => (
              <FAQItem key={index}>
                <FAQItemLeft>
                  <SvgCheckmark />
                  <H3>{question}</H3>
                </FAQItemLeft>
                <ContentMarkdown content={answer} />
              </FAQItem>
            ))}
          </BottomContainer>
        </HomeFAQInner>
      </Container>
    </HomeFAQWrapper>
  )
}

const HomeFAQWrapper = styled.div`
  padding: 4rem 0;
  background: ${grabColor('backgroundLight')};

  ${H2Alt} {
    color: ${({ theme }) => theme.colors.brand.darker};
  }

  ${H3} {
    color: ${({ theme }) => theme.colors.brand.darker};
    margin: 0;
  }

  ${TextBody} {
    margin: 0;
    strong {
      font-weight: 600;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: 40px 0 3rem;
  }
`

const BottomContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 65px;
  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-template-columns: 1fr;
  }
`

const FAQItem = styled.div`
  display: grid;
  grid-template-columns: 210px 1fr;
  grid-gap: 2rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`

const FAQItemLeft = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 21px;

  svg {
    display: block;
    margin-top: 2px;
    color: ${grabColor('brand.DEFAULT')};
  }
`

const HomeFAQInner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 56px;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-template-columns: 1fr;
    gap: 40px 0;
  }
`
