import Head from 'next/head'
import styled from 'styled-components'
import { getAllArticles, getAllVerticals, getPage } from 'src/utils/contentful'
import Layout from 'src/components/Layout'
import HomeHero from 'src/components/Home/Hero'
import HomeSubHero from 'src/components/Home/SubHero'
import ValueProposal from 'src/components/Home/ValueProposal'
import InsurersParade from 'src/components/Home/InsurersParade'
import HomeFAQs from 'src/components/Home/FAQs'

export default function Home({ verticals, page }) {
  return (
    <Layout verticals={verticals} seo={page.seo} footer={{ rx: true }}>
      <main>
        <HomeHero verticals={verticals} />

        <HomeSubHero />

        <ValueProposal />

        <InsurersParade />

        <HomeFAQs faqList={page.faqList} />

        {/* <ArticlesContainer>
          {articles.map((article, index) => (
            <Link
              key={index}
              href={`/${article.vertical.slug}/learn/[id]`}
              as={`/${article.vertical.slug}/learn/${article.slug}`}
              passHref
            >
              <A>
                <ArticleOverview
                  title={article.title}
                  image={article.photo.url}
                  subtitle={article.shortDescription}
                  publicationDate={article.sys.publishedAt}
                />
              </A>
            </Link>
          ))}
        </ArticlesContainer> */}
      </main>
    </Layout>
  )
}

export async function getStaticProps() {
  const articles = await getAllArticles({ limit: 5 })
  const verticals = await getAllVerticals()
  const page = await getPage('dashboard')

  return {
    props: {
      articles,
      verticals,
      page,
    },
  }
}

const ArticlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const A = styled.a`
  text-decoration: none;
  color: inherit;
`
