import Image from "next/image";
import Link from 'next/link'
import styled from 'styled-components'
import Container from 'src/styles/Container'
import { H1, SubHeading1, SectionHeading, H2Alt, TextBody } from 'src/styles/Typography'
import grabColor from 'src/utils/grabColor'

export default function HomeHero({ verticals }) {
  return (
    <HeroWrapper>
      <Container>
        <HeroTitle>
          <H1 margin="1rem 0">Find your best insurance – for less.</H1>
          <SubHeading1>
            <strong>PolicyScout</strong> cuts through insurance confusion to uncover your
            best options, both now and later.
          </SubHeading1>
          <SectionHeading color="sectionHeading">
            What do you need to protect?
          </SectionHeading>
        </HeroTitle>
        <HeroVerticals>
          {verticals.slice(0, 5).map((vertical) => {
            const color = vertical.slug.replace('-insurance', '')
            return (
              <Link href={`/${vertical.slug}`} key={vertical.slug} passHref legacyBehavior>
                <Anchor color={color}>
                  <HeroImageContainer>
                    <Image
                      src={vertical.hero.photo.url}
                      alt={vertical.title}
                      width={vertical.hero.photo.width}
                      height={vertical.hero.photo.height}
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      quality={10}
                      priority
                    />
                  </HeroImageContainer>
                  <div>
                    <H2Alt>{vertical.title.replace(' Insurance', '')}</H2Alt>
                    <TextBody>{vertical.flyoutNavigationTagline}</TextBody>
                  </div>
                </Anchor>
              </Link>
            );
          })}
        </HeroVerticals>
      </Container>
    </HeroWrapper>
  );
}

const HeroWrapper = styled.div`
  padding: 3rem 0;
  background: ${grabColor('backgroundLight')};

  ${SubHeading1} {
    margin-bottom: 3rem;
    padding-top: 0.5rem;
    strong {
      font-weight: 600;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
      margin-bottom: 42px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: 1.5rem 0;
  }
`

const HeroTitle = styled.div`
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    text-align: left;
  }
`

const HeroVerticals = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin: 36px 0 96px;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    margin: 42px 0 1.5rem;
  }
`

const HeroImageContainer = styled.div`
  position: relative;
  width: 248px;
  height: 208px;
  border-bottom: 8px solid ${({ theme }) => theme.colors.action.DEFAULT};
  img {
    object-fit: cover;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    width: 124px;
    height: 124px;
    border-bottom: unset;
  }
`

const Anchor = styled.a`
  text-decoration: none;

  ${H2Alt} {
    margin: 1rem 0 0;

    @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
      margin: 0;
      padding: 12px 0 0;
      border-top: 6px solid ${({ theme }) => theme.colors.action.DEFAULT};
    }
  }

  ${TextBody} {
    margin: 8px 0;
    max-width: 90%;

    @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
      grid-column: 2;
    }
  }

  &:hover {
    ${H2Alt} {
      text-decoration: underline;
    }

    /* ${HeroImageContainer} {
      border-bottom: 8px solid ${({ color, theme }) => theme.colors[color].DEFAULT};
    } */
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: grid;
    grid-template-columns: 124px 1fr;
    grid-gap: 1.5rem;

    & + & {
      margin-top: 2rem;
    }
  }
`
