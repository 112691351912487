function SvgIdentityIcon(props) {
  return (
    <svg viewBox="0 0 48 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="a-identity-icon-mask"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={3}
        y={2}
        width={42}
        height={50}
      >
        <path
          d="M5.885 10.581c-1.07.188-1.931 1.022-2.03 2.104a41.316 41.316 0 00-.17 3.755c0 14.616 7.682 27.401 19.15 34.38a2.239 2.239 0 002.33 0c11.468-6.979 19.15-19.764 19.15-34.38 0-1.256-.056-2.499-.167-3.726-.099-1.086-.965-1.923-2.04-2.107A38.594 38.594 0 0125.485 3.44a2.617 2.617 0 00-3.13 0 38.597 38.597 0 01-16.47 7.142z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#a-identity-icon-mask)">
        <path
          d="M5.885 10.581c-1.07.188-1.931 1.022-2.03 2.104a41.316 41.316 0 00-.17 3.755c0 14.616 7.682 27.401 19.15 34.38a2.239 2.239 0 002.33 0c11.468-6.979 19.15-19.764 19.15-34.38 0-1.256-.056-2.499-.167-3.726-.099-1.086-.965-1.923-2.04-2.107A38.594 38.594 0 0125.485 3.44a2.617 2.617 0 00-3.13 0 38.597 38.597 0 01-16.47 7.142z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 26.2a6 6 0 100-12 6 6 0 000 12zm12.08-.64a4.4 4.4 0 100-8.8 4.4 4.4 0 000 8.8zm-19.76-4.4a4.4 4.4 0 11-8.8 0 4.4 4.4 0 018.8 0zm17.633 31.023c2.74-2.913 4.447-7.017 4.447-11.564 0-5.198-2.231-9.817-5.687-12.74A9.954 9.954 0 0136.8 27C42.986 27 48 32.73 48 39.8s-5.014 12.8-11.2 12.8a9.899 9.899 0 01-2.847-.417zM15.287 27.879C11.831 30.802 9.6 35.421 9.6 40.62c0 4.547 1.707 8.65 4.447 11.564a9.899 9.899 0 01-2.847.417C5.014 52.6 0 46.87 0 39.8S5.014 27 11.2 27c1.442 0 2.821.312 4.087.88zM35.2 40.62c0 7.07-5.014 12.8-11.2 12.8-6.186 0-11.2-5.73-11.2-12.8 0-7.069 5.014-12.8 11.2-12.8 6.186 0 11.2 5.731 11.2 12.8z"
          fill="#27788B"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.284 16.44c0-1.34.061-2.665.18-3.973.21-2.291 2.003-3.9 4.006-4.25 5.702-1 10.959-3.341 15.448-6.7a5.017 5.017 0 016.005 0 36.193 36.193 0 0015.59 6.724c2.012.345 3.817 1.956 4.025 4.257.118 1.298.178 2.613.178 3.942 0 15.473-8.136 29.025-20.304 36.43a4.638 4.638 0 01-4.824 0C9.42 45.466 1.284 31.913 1.284 16.44zm2.57-3.755c.1-1.082.96-1.917 2.03-2.104a38.597 38.597 0 0016.472-7.142 2.617 2.617 0 013.129 0 38.594 38.594 0 0016.623 7.168c1.075.184 1.942 1.021 2.04 2.107.11 1.227.168 2.47.168 3.726 0 14.616-7.683 27.4-19.152 34.38a2.239 2.239 0 01-2.328 0C11.366 43.84 3.684 31.056 3.684 16.44c0-1.266.058-2.519.17-3.755z"
        fill="#03A8D9"
      />
    </svg>
  )
}

export default SvgIdentityIcon
