import Image from 'next/legacy/image'
import styled from 'styled-components'
import Container from 'src/styles/Container'
import { H3 } from 'src/styles/Typography'
import aigLogo from 'src/components/icons/insurersParade/aig.svg'
import lemonadeLogo from 'src/components/icons/insurersParade/lemonade.svg'
import geicoLogo from 'src/components/icons/insurersParade/geico.svg'
import statefarmLogo from 'src/components/icons/insurersParade/state-farm.svg'
import progressiveLogo from 'src/components/icons/insurersParade/progressive.svg'
import ladderLogo from 'src/components/icons/insurersParade/ladder.svg'
import aetnaLogo from 'src/components/icons/insurersParade/aetna-logo.svg'
import bcLogo from 'src/components/icons/insurersParade/bc-logo.svg'
import omahaLogo from 'src/components/icons/insurersParade/omaha-logo-new-horizontal.svg'
import prosperity from 'src/components/icons/insurersParade/logo_prosperity.png'

export default function HomeInsurersParade({ borderTopSlug = false }) {
  return (
    <HomeInsurersParadeWrapper borderTopSlug={borderTopSlug}>
      <Container>
        <HomeInsurersParadeInner>
          <div>
            <H3>
              Find <span className="hide-desktop">the</span>
              <span className="hide-mobile">your</span> best options across top insurers
            </H3>
          </div>
          <Right>
            {/* temp remove AIG */}
            {/* <Image objectFit="contain" src={aigLogo} alt="AIG" /> */}
            <Image objectFit="contain" src={ladderLogo} alt="Ladder" />
            <Image objectFit="contain" src={aetnaLogo} alt="Aetna" />
            {/* <Image objectFit="contain" src={bcLogo} alt="BlueCross BlueShield" /> */}
            <Image objectFit="contain" src={omahaLogo} alt="Mutual of Ohmaha" />
            <div
              style={{
                maxWidth: '170px',
              }}
            >
              <Image objectFit="contain" src={prosperity} alt="Prosperity" />
            </div>
            {/* <Image objectFit="contain" src={statefarmLogo} alt="State Farm" /> */}
            {/* <Image objectFit="contain" src={progressiveLogo} alt="Progressive" /> */}
            {/* <Image objectFit="contain" src={geicoLogo} alt="Geico" /> */}
            {/* <Image objectFit="contain" src={lemonadeLogo} alt="Lemonaid" /> */}
          </Right>
        </HomeInsurersParadeInner>
      </Container>
    </HomeInsurersParadeWrapper>
  )
}

const HomeInsurersParadeWrapper = styled.div`
  padding: 3rem 0;
  background: ${({ theme }) => theme.colors.brand.darker};
  border-top: ${({ borderTopSlug, theme }) =>
    borderTopSlug ? `6px solid ${theme.colors[borderTopSlug].DEFAULT}` : 'none'};
  overflow-x: hidden;

  ${H3} {
    color: white;
    margin: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: 3rem 0 56px;
  }
`

const Right = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, min-content);
  grid-auto-flow: column;
  grid-gap: 54px;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-template-columns: repeat(2, 128px);
    grid-auto-flow: row;
  }
`

const HomeInsurersParadeInner = styled.div`
  display: flex;
  gap: 56px;
  width: 100%;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    text-align: center;
    margin: 0 auto;
  }
`
