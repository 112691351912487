import styled from 'styled-components'
import Container from 'src/styles/Container'
import { H2Alt, H3, TextBody, Detail } from 'src/styles/Typography'
import grabColor from 'src/utils/grabColor'
import SvgCheckmark from 'src/components/icons/CheckMark'

export default function HomeValueProposal({ overrideBgColor = false }) {
  return (
    <HomeValueProposalWrapper overrideBgColor={overrideBgColor}>
      <Container>
        <HomeValueProposalInner>
          <div>
            <H2Alt>Finding insurance shouldn’t hurt. </H2Alt>
            <TextBody>
              <strong>PolicyScout</strong> works 24x7 to find the right information to
              help you protect what matters most, for less.
            </TextBody>
          </div>
          <RightContainer>
            <RightItem>
              <SvgCheckmark />
              <div>
                <H3>
                  Clear <br />
                  Information
                </H3>
                <Detail>Cut through the noise to understand what truly matters.</Detail>
              </div>
            </RightItem>
            <RightItem>
              <SvgCheckmark />
              <div>
                <H3>Personalized Recommendations</H3>
                <Detail>Uncover the insurance options for your unique needs.</Detail>
              </div>
            </RightItem>
            <RightItem>
              <SvgCheckmark />
              <div>
                <H3>
                  Independent <br />
                  Honest Reviews
                </H3>
                <Detail>
                  See how the options measure up for value, service, and quality.
                </Detail>
              </div>
            </RightItem>
          </RightContainer>
        </HomeValueProposalInner>
      </Container>
    </HomeValueProposalWrapper>
  )
}

const HomeValueProposalWrapper = styled.div`
  padding: 4rem 0;
  background: ${({ theme, overrideBgColor }) =>
    overrideBgColor ? grabColor(overrideBgColor) : theme.colors.backgroundMid};

  ${H2Alt} {
    color: ${({ theme }) => theme.colors.brand.darker};
  }

  ${H3} {
    color: ${({ theme }) => theme.colors.brand.darker};
    margin: 0;

    @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
      br {
        display: none;
      }
    }
  }

  ${TextBody} {
    margin: 1rem 0 0 0;
    strong {
      font-weight: 600;
    }
  }

  ${Detail} {
    margin: 0.5rem 0 0 0;
    color: ${grabColor('heading')};
    @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
      margin: 1rem 0 0 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: 40px 0 3rem;
  }
`

const RightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    flex-direction: column;
    gap: 2rem 0;
  }
`

const RightItem = styled.div`
  display: grid;
  grid-template-columns: auto 192px;
  gap: 1.1rem;

  svg {
    display: block;
    margin-top: 2px;
    color: ${grabColor('brand.DEFAULT')};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-template-columns: 20px 1fr;
  }
`

const HomeValueProposalInner = styled.div`
  display: grid;
  grid-template-columns: 424px 1fr;
  gap: 0 102px;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-template-columns: 1fr;
    gap: 40px 0;
  }
`
