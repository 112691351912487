import Link from 'next/link'
import styled from 'styled-components'
import Container from 'src/styles/Container'
import { StyledAnchor } from 'src/styles/Button'
import { H3, H2Alt, TextBody, Detail, SectionHeading } from 'src/styles/Typography'
import grabColor from 'src/utils/grabColor'
import SvgIdentityIcon from './IdentityIcon'
import SvgRxIcon from 'src/components/icons/RxIcon'

export default function HomeSubHero() {
  return (
    <SubHeroWrapper>
      <Container>
        <SubHeroInner>
          <SectionHeading>
            PolicyScout Member Benefits for free
            <span>
              – become a member today, <u>for free</u>.
            </span>
          </SectionHeading>
          <BottomWrapper>
            <div>
              <IdentifyTheftProtection>
                <ImgWrapper>
                  <SvgIdentityIcon />
                </ImgWrapper>
                <H2Alt>Save 50% on world-class</H2Alt>
                <H2Alt>Identity Protection</H2Alt>
              </IdentifyTheftProtection>
              <TextBody>
                You do everything online. Help your entire family <br />
                do it safely – <strong>starting at only $6/mo.</strong>
              </TextBody>
              <Link href="/identity" passHref legacyBehavior>
                <Anchor size="large" width="320px">
                  Get Protected Today
                </Anchor>
              </Link>
            </div>
            <Rule />
            <div>
              <RxCard>
                <ImgWrapper>
                  <SvgRxIcon />
                </ImgWrapper>
                <H2Alt>
                  Save up to $95 on your prescription with our{' '}
                  <span>Free Rx Discount Card</span> <sup>*</sup>
                </H2Alt>
              </RxCard>
              <TextBody>
                PolicyScout has secured for you discounted Rx prices across 35,000
                pharmacies nationally.
              </TextBody>
              <Link href="/rx/download" passHref legacyBehavior>
                <Anchor size="large" width="320px">
                  Get Your Free Rx Card
                </Anchor>
              </Link>
            </div>
          </BottomWrapper>
        </SubHeroInner>
      </Container>
    </SubHeroWrapper>
  )
}

const Rule = styled.div`
  width: 1px;
  height: 280px;
  background: ${grabColor('borderContent')};

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: none;
  }
`

const BottomWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1px 1fr;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`

const SubHeroWrapper = styled.div`
  background: ${grabColor('backgroundLight')};

  .hide-mobile {
    @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
      display: none;
    }
  }
  .hide-desktop {
    display: none;
    @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
      display: inherit;
    }
  }
`

const ImgWrapper = styled.div`
  svg {
    width: 61px;
    height: 72px;
  }
`

const RxCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  ${H2Alt} {
    text-align: center;
    max-width: 414px;
    sup {
      color: ${grabColor('detail')};
    }
    span {
      color: ${grabColor('rx.DEFAULT')};
    }
  }

  ${H2Alt} + ${H2Alt} {
    margin-top: -8px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    flex-direction: column;
    gap: 0;

    > ${ImgWrapper} {
      order: -1;
      margin-bottom: 12px;
    }
  }
`

const IdentifyTheftProtection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  ${H2Alt} {
    text-align: center;
  }

  ${H2Alt} + ${H2Alt} {
    color: ${grabColor('identity.DEFAULT')};
    margin-top: -8px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    flex-direction: column;
    gap: 0;

    > ${ImgWrapper} {
      order: -1;
      margin-bottom: 12px;
    }
  }
`

const Anchor = styled(StyledAnchor)`
  margin: 1.5rem auto 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    max-width: 352px;
    width: 100%;
    margin: 0 auto;
  }
`

const SubHeroInner = styled.div`
  padding: 3rem 0 5rem;
  border-top: 1px solid ${({ theme }) => theme.colors.borderContent};

  ${SectionHeading} {
    text-align: center;
    color: ${grabColor('brand.dark')};
    margin-bottom: 3rem;
    span {
      text-transform: none;
      color: ${grabColor('detail')};
      font-size: 14px;
      line-height: 24px;
      font-style: italic;
      font-weight: 400;
    }
  }

  ${TextBody} {
    text-align: center;
    margin: 1rem auto;
    max-width: 414px;
  }

  ${H3} {
    margin: 0;
  }
`
